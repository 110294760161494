import {
  Conversation,
  ConversationMap,
  ConversationsResponse,
  ConversationState,
} from "../../services/api_types"

export interface ConversationLists {
  inbox: Conversation[]
  active: Conversation[]
  completed: Conversation[]
}

const filterKeys = (
  map: ConversationMap,
  state: ConversationState
): string[] => {
  return Object.keys(map).filter(theId => {
    return map[theId].conversation_state === state
  })
}

export const buildConversationLists = (
  conversations: ConversationsResponse,
  is_expert: boolean
): ConversationLists => {
  if (!conversations) {
    return {
      inbox: [],
      active: [],
      completed: [],
    }
  }

  if (conversations.data) {
    conversations = conversations.data
  }

  const accountInboxKeys = filterKeys(
    conversations.account_conversations,
    ConversationState.inbox
  )

  const accountActiveKeys = filterKeys(
    conversations.account_conversations,
    ConversationState.active
  )

  const accountCompletedKeys = filterKeys(
    conversations.account_conversations,
    ConversationState.completed
  )

  const fromAccounts = (keys: string[]): Conversation[] => {
    return keys.map(key => {
      return conversations.account_conversations[key]
    })
  }

  if (!is_expert) {
    return {
      inbox: fromAccounts(accountInboxKeys),
      active: fromAccounts(accountActiveKeys),
      completed: fromAccounts(accountCompletedKeys),
    }
  }

  const fromProfiles = (keys: string[]): Conversation[] => {
    return keys.map(key => {
      return conversations.profile_conversations[key]
    })
  }

  const expertInboxKeys = filterKeys(
    conversations.profile_conversations,
    ConversationState.inbox
  )

  const expertActiveKeys = filterKeys(
    conversations.profile_conversations,
    ConversationState.active
  )

  const expertCompletedKeys = filterKeys(
    conversations.profile_conversations,
    ConversationState.completed
  )

  return {
    inbox: Array.prototype.concat(
      fromProfiles(expertInboxKeys),
      fromAccounts(accountInboxKeys)
    ),
    active: Array.prototype.concat(
      fromProfiles(expertActiveKeys),
      fromAccounts(accountActiveKeys)
    ),
    completed: Array.prototype.concat(
      fromProfiles(expertCompletedKeys),
      fromAccounts(accountCompletedKeys)
    ),
  }
}
