import React, { useContext, useState } from "react"
import { useEffect } from "react"
import ProfileInfoCard from "../shared/profile_info_card"
import Wrapper from "../shared/wrapper"
import { ApiContext, UserContext, ConversationsContext } from "../user_wrapper"
import { Conversations } from "./conversations"
// import { Conversations } from "./conversations"
import {
  ConversationLists,
  buildConversationLists,
} from "./conversations.utils"

// TODO add a CTA on home page with copy button to prompt to get people
// TODO onClick of clipboard copy button we should show a success animation

const Home = () => {
  const user = useContext(UserContext)
  const conversations = useContext(ConversationsContext)
  console.log("USER", user)
  console.log("Conversations: ", conversations.data)

  return (
    <Wrapper>
      <ProfileInfoCard linkCopy="Edit Profile" linkTo="/edit_profile" />
      <Conversations
        isExpert={user.data?.account?.is_expert}
        conversationLists={buildConversationLists(
          conversations.data,
          !!user.data?.account?.is_expert
        )}
      />
    </Wrapper>
  )
}

export default Home
