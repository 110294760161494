export interface AccountResponseData {
  account_id: string
  email: string
  name: string
  profile_image_url: string
  is_expert: boolean
  is_admin: boolean
  has_filled_out_profile: boolean
  is_approved_profile: boolean
  stripe_customer_id: string
  stripe_account_id: string
  stripe_enabled: boolean
}

export interface GetAccountResponse {
  is_anonymous: boolean
  account: AccountResponseData | undefined
}

export interface LoginResponse {
  token: string
  account: AccountResponseData
}

export interface UpdateAccountRequest {
  name?: string
  profile_image_url?: string
}

export interface UploadProfilePhotoResponse {
  profile_image_url: string
}

export interface UploadIntroVideoResponse {
  profile_intro_video_url: string
}

export interface ProfileResponseData {
  profile_id: string
  account_id: string
  ask_price: string
  currency_code: string
  profile_about: string
  profile_offering_one: string
  profile_offering_two: string
  profile_offering_three: string
  profile_slug: string
  profile_response_time: string
  profile_intro_video_url: string
  profile_instagram_link: string
  profile_score: number
  is_active: boolean
}

export interface ProfileUpdateRequest {
  profile: ProfileResponseData
}

export interface ProfileResponse {
  profile: ProfileResponseData
  account: AccountResponseData
}

export interface CreateProfileResponse {
  profile_id: string
  account_id: string
}

export interface ProfileAccountData {
  account_id: string
  email: string
  name: string
  profile_image_url: string
  is_approved_profile: boolean
  stripe_account_id: string
  stripe_enabled: boolean
}

export interface PublicProfileResponse {
  profile: ProfileResponseData
  account: ProfileAccountData
}

export interface AccountInfo {
  name: string
  profile_image_url: string
}

export interface AskAccountInfo {
  account_info: AccountInfo
  profile_account_info: AccountInfo
}

export interface TimeSince {
  minutes_since?: number
  hours_since?: number
  days_since?: number
  weeks_since?: number
  months_since?: number
}

export interface Ask {
  ask_id: string
  account_id: string
  profile_id: string
  account_last_ask_event_id: string
  expert_last_ask_event_id: string
  account_last_seen_ask_event_id: string
  expert_last_seen_ask_event_id: string
  price: string
  is_shown_on_expert_page: boolean
  expert_ask_description: string
  account_ask_video_url: string
  expert_answer_video_url: string
  is_account_ask_video_short: boolean
  account_ask_video_thumbnail_url: string | undefined
  expert_answer_video_thumbnail_url: string | undefined
  review_score: string
  created_at: string
  updated_at: string
  time_since: TimeSince
  ask_events: AskEvent[]
}

export interface GetAskResponse {
  ask: Ask
}

export interface ConversationAskProfile {
  profile_id: string
  profile_name: string
  profile_image_url: string
}

export interface ConversationAccount {
  account_id: string
  account_name: string
  profile_image_url: string
  profile_id?: string
}

export enum ConversationState {
  inbox = "inbox",
  active = "active",
  completed = "completed",
}

export interface Conversation {
  asks: Ask[]
  notifications: number
  time_since: TimeSince
  account: ConversationAccount
  profile: ConversationAskProfile
  conversation_state: ConversationState
  last_updated: string
}

export interface ConversationMap {
  [key: string]: Conversation
}

export interface ConversationsResponse {
  account_conversations: ConversationMap
  profile_conversations: ConversationMap
}

export interface SubmitVideoRequest {
  video_url: string
}

export interface SubmitVideoResponse {
  video_url: string
}

export interface AskEvent {
  ask_event_id: string
  ask_id: string
  event_name: string
}

export interface CreateAskEvent extends AskEvent {
  payload: {
    ask_id: string
    account_id: string
    profile_id: string
    price: string
    expert_last_ask_event_id: string
  }
}

export interface PayRequest {
  stripe_token: any
}

export interface DeclineAskResponse {
  success: boolean
}

export interface StripePaymentIntent {
  client_secret: string
}

export interface PayResponse {
  payment_intent: StripePaymentIntent
}

export interface StripeOnboardingStatus {
  details_submitted: boolean
  charges_enabled: boolean
  payouts_enabled: boolean
}

export interface CreateStripeLinkResponse {
  account_link_url: string
}

export enum FileUploadType {
  SUBMIT_ASK_VIDEO = "SUBMIT_ASK_VIDEO",
  SUBMIT_ANSWER_VIDEO = "SUBMIT_ANSWER_VIDEO",
  UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE",
  UPDATE_PROFILE_VIDEO = "UPDATE_PROFILE_VIDEO",
  SUBMIT_CLARIFYING_QUESTION_VIDEO = "SUBMIT_CLARIFYING_QUESTION_VIDEO",
  SUBMIT_CLARIFYING_ANSWER_VIDEO = "SUBMIT_CLARIFYING_ANSWER_VIDEO",
}

export interface AwsCreateUploadUrlFields {
  key: string
  policy: string
  "x-amz-algorithm": string
  "x-amz-credential": string
  "x-amz-date": string
  "x-amz-signature": string
}

export interface AwsCreateUploadUrlResponse {
  url: string
  fields: AwsCreateUploadUrlFields
}

export interface CreateUploadUrlRequest {
  file_upload_type: string
  file_extension: string
  entity_id: string
}

export interface CreateUploadUrlResponse {
  upload_url: AwsCreateUploadUrlResponse
}

export interface Attachments {
  link_title_1: string
  link_1: string
  link_title_2: string
  link_2: string
  link_title_3: string
  link_3: string
}

export interface Review {
  review_score: number
  review: string
}

export interface AttachLinksEvent extends AskEvent {
  payload: Attachments
}

export interface AttachLinksRequest {
  attachments: Attachments
}

export interface SubmitExpertAskDescriptionRequest {
  expert_ask_description: SubmitExpertAskDescriptionWrapper
}

export interface SubmitExpertAskDescriptionWrapper {
  expert_ask_description: string
}

export interface SubmitExpertAskDescriptionEvent extends AskEvent {
  payload: SubmitExpertAskDescriptionWrapper
}

export interface ExpertInviteResponse {
  confirmation_code: string
}
